:root {
  --main-background: #000;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  background-color: var(--main-background);
}

.lightboxoverlay {
  z-index: 9999999;
}
.lightbox {
  z-index: 9999999;
}
/* .lg-backdrop {
  z-index: 9999999;
} */

.myScrollContainer::-webkit-scrollbar-track {
  background: #f1f1f100;
}

.myScrollContainer::-webkit-scrollbar-thumb {
  background: #efecec45;
}
